<template>
  <div>
    <form class="login" @submit.prevent="login">
      <h1>Connexion</h1>
      <input
        required
        type="password"
        v-model="password"
        placeholder="Mot de passe"
      />
      <hr />
      <button type="submit">Connexion</button>
    </form>
    <span id="error" v-if="error" v-text="error" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      AccessPassword: "carnaval44#",
      password: "",
      error: null,
    };
  },
  methods: {
    login() {
      if (this.password == this.AccessPassword) {
        this.$store.commit("AUTH_VALID");
      } else {
        this.error = "Mauvais Mot de passe. Veuillez réessayer.";
      }
    },
  },
};
</script>

<style scoped>
#error {
  color: red;
}
</style>